import { getCookieValue, removeCookie } from "./context/cookies";
import { IDENTITY_SDK_RESET_COOKIE } from "./constants";
import {
  setLoggedInState,
  setUserProfile,
  setUserFetchingState,
  SUCCESS,
  FETCHING,
  FAILED
} from "../template/state/modules/user";
import { stripSubdomain } from "../helpers/url";
import { asosGlobalNamespace, sdkGlobalNamespace } from "../template/constants";

const fetchUserProfile = async store => {
  const sdkNamespace = window[asosGlobalNamespace][sdkGlobalNamespace];

  if (!sdkNamespace.identity) return;

  const { identity: identitySDK } = sdkNamespace;

  if (getCookieValue(IDENTITY_SDK_RESET_COOKIE) === "true") {
    const domain = `.${stripSubdomain(window.location.hostname)}`;

    identitySDK.customer.resetLocalCopy();
    removeCookie(IDENTITY_SDK_RESET_COOKIE, domain);
  }

  store.dispatch(setUserFetchingState(FETCHING));

  try {
    const customer = identitySDK.customer;
    const profile = await customer.profile();

    if (!profile) {
      throw new Error("Empty profile");
    }

    store.dispatch(setUserProfile(profile));
    store.dispatch(setLoggedInState(await customer.isAuthenticated()));
    store.dispatch(setUserFetchingState(SUCCESS));
  } catch (error) {
    store.dispatch(setUserFetchingState(FAILED));
  }
};

export default store => {
  if (window[asosGlobalNamespace][sdkGlobalNamespace].identity)
    return fetchUserProfile(store);

  return window[asosGlobalNamespace].eventBus.addListener("sdk-ready", () =>
    fetchUserProfile(store)
  );
};
